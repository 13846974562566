<template>
  <validation-observer ref="loginForm">
    <div class="bg-login">
      <header class="header-new">
        <div class="text-center">
          <img
            :src="logo_paths"
            alt="logo"
            height="55"
          >
        </div>
      </header>

      <div class="container-log">
        <div class="d-flex align-items-center padder">
          <router-link :to="{ name: 'tanghuay-round' }">
            <button
              class="bnt-home"
            >
              <i class="fas fa-volume-up" />
            </button>
          </router-link>
          <div class="txt-right">
            <marquee>
              เว็บคาสิโนออนไลน์ น้องใหม่แบบควบวงจร ฝาก-ถอน รวดเร็วไม่เหมือนใคร ด้วยระบบอัตโนมัติล้ำสมัย
            </marquee>
          </div>
        </div>
        <div class="form-login vip">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    type="number"
                    placeholder="กรอกเบอร์โทรศัพท์"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    :state="errors.length > 0 ? false : null"
                    maxlength="10"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกเบอร์โทรศัพท์" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control2"
                    placeholder="รหัสผ่าน"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "กรุณากรอกรหัสผ่านให้ถูกต้อง" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="col-md-12 mb-1">
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  v-model="status"
                  value="checkbox"
                >
                  <span class="font-weight-bolder text-black">จดจำรหัสผ่าน</span>
                </b-form-checkbox>
                <a :href="agent.linecontact">
                  <span class="font-weight-bolder text-danger">ลืมรหัสผ่าน</span>
                </a>
              </div>

            </div>

            <div class="col-md-12 mb-1 pl-2 pr-2">
              <button
                class="btn-green animate__animated animate__pulse animate__infinite animated-button1 btn btn-block btn-lg"
                type="submit"
                @click="login()"
              >
                <i class="fas fa-sign-in-alt" /> เข้าสู่ระบบ
                <span />
                <span />
                <span />
                <span />
              </button>
            </div>

            <div class="col-md-12 mb-1 pl-2 pr-2">
              <button
                class="btn-danger animate__animated animate__pulse animate__infinite animated-button1 btn btn-block btn-lg"
                @click="registerLink()"
              >
                <i class="fas fa-user-plus" /> สมัครสมาชิก
                <span />
                <span />
                <span />
                <span />
              </button>
            </div>

            <!-- <div class="col-md-12 mt-2 text-center">
            <router-link
              :to="{ name : 'forgot-password'}"
              style="color: #000"
            >
              ลืมรหัสผ่าน? คลิกที่นี่
            </router-link>
          </div> -->
          </div>
        </div>
      </div>

      <div class="bg-two-container">
        <div class="bg_head_txt">
          คาสิโน สล็อต ออนไลน์
        </div>

        <div class="banner-slider mt-1 mb-1">
          <div class="banner-slider-item">
            <swiper
              class="swiper-multiple"
              :options="swiperOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            >
              <swiper-slide
                v-for="(data,index) in swiperData"
                :key="index"
              >
                <b-img
                  :src="data.img"
                  fluid
                  @click="comingSoon()"
                />
              </swiper-slide>

              <div
                slot="pagination"
                class="swiper-pagination"
              />
            </swiper>
          </div>
        </div>

        <div class="bg_head_txt">
          หวยออนไลน์
        </div>

        <b-row class="mt-2 text-center">
          <b-col lg="12">
            <div class="text-center header-bg-content mb-1">
              <div class="mb-1 d-flex justify-content-center align-items-center">
                <img
                  src="lotto/th.png"
                  alt=""
                  class="img-lot"
                  height="35px"
                >
                <span class="YrVDG">
                  ผลสลากกินแบ่งรัฐบาล {{ ResultThaiDate ? ResultThaiDate : 'วว/ดด/ปปปป' }}
                </span>
              </div>
              <b-row>
                <b-col
                  lg="12"
                  cols="12"
                  class="number-bg-content"
                >
                  <h1>{{ ResultThai.R1 ? ResultThai.R1 : 'XXXXXX' }}</h1>
                  <small>รางวัลที่ 1</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                  class="number-bg-content"
                >
                  <h3>
                    {{ ResultThai.F3N1 ? ResultThai.F3N1 : 'XXX' }} {{ ResultThai.F3N2 ? ResultThai.F3N2 : 'XXX' }}
                  </h3>
                  <small>เลขหน้า 3 ตัว</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                  class="number-bg-content"
                >
                  <h3>
                    {{ ResultThai.B3N1 ? ResultThai.B3N1 : 'XXX' }} {{ ResultThai.B3N2 ? ResultThai.B3N2 : 'XXX' }}
                  </h3>
                  <small>เลขท้าย 3 ตัว</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                  class="number-bg-content"
                >
                  <h3>{{ ResultThai.D2 ? ResultThai.D2 : 'XX' }}</h3>
                  <small>สองตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            lg="6"
            cols="12"
          >
            <!-- Lotto THAI -->
            <div class="header-bg-content mb-1">
              <div class="mb-1 d-flex justify-content-center align-items-center">
                <img
                  src="lotto/gsb.png"
                  alt=""
                  class="img-lot"
                  height="35"
                >&nbsp;
                <span class="YrVDG">
                  หวย ออมสิน {{ ResultAomDate ? ResultAomDate : 'วว/ดด/ปปปป' }}
                </span>
              </div>
              <b-row>
                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultAom.U3 ? ResultAom.U3 : 'XXX' }}</h3>
                  <small>3ตัวบน</small>
                </b-col>

                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultAom.D2 ? ResultAom.D2 : 'XXX' }}</h3>
                  <small>2ตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
          <!-- End Lotto THAI -->
          </b-col>

          <b-col
            lg="6"
            cols="12"
          >
            <div class="header-bg-content mb-1">
              <div class="mb-1 d-flex justify-content-center align-items-center">
                <img
                  src="lotto/tks.png"
                  alt=""
                  class="img-lot"
                  height="35"
                >&nbsp;
                <span class="YrVDG">
                  หวย ธกส. {{ ResultTKSDate ? ResultTKSDate : 'วว/ดด/ปปปป' }}
                </span>
              </div>
              <b-row>
                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultTKS.U3 ? ResultTKS.U3 : 'XXX' }}</h3>
                  <small>3ตัวบน</small>
                </b-col>

                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultTKS.D2 ? ResultTKS.D2 : 'XX' }}</h3>
                  <small>2ตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
          <!-- End Lotto THAI -->
          </b-col>

          <b-col
            lg="12"
            cols="12"
          >
            <!-- Lotto ยี่กี -->
            <div class="header-bg-content mb-1">
              <div class="mb-1 d-flex justify-content-center align-items-center">
                <img
                  src="lotto/yigy_special.png"
                  alt=""
                  class="img-lot"
                  height="35"
                >&nbsp;
                <span class="YrVDG">
                  จับยี่กี รอบที่ {{ ResultYGRoundNo }} ประจำวันที่ {{ ResultYGLastDate ? ResultYGLastDate : 'วว/ดด/ปปปป' }}
                </span>
              </div>
              <b-row>
                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultYGLast.U3 ? ResultYGLast.U3 : 'XXX' }}</h3>
                  <small>3ตัวบน</small>
                </b-col>

                <b-col
                  lg="6"
                  cols="6"
                  class="number-bg-content"
                >
                  <h3>{{ ResultYGLast.D2 ? ResultYGLast.D2 : 'XX' }}</h3>
                  <small>2ตัวล่าง</small>
                </b-col>
              </b-row>
            </div>

          <!-- End Lotto THAI -->
          </b-col>
          <b-col lg="12">
            <div class="txt-hd mt-1 mb-1">
              <h3>หวยหุ้นต่างประเทศ ประจำวันที่ {{ dateshow }}</h3>
            </div>

            <div class="row gutters-10">
              <div
                v-for="set in list"
                :key="set"
                class="col-md-4"
              >
                <div class="box-lotto">
                  <div class="box-title">
                    <img
                      :src="`/assets/themes/front2021/images/${set.picture}`"
                      class="img-title"
                    ><strong>{{ set.name }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="set.result1 > 0"
                        class="lot-num"
                      >
                        {{ set.result1 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="set.result2 > 0"
                        class="lot-num"
                      >
                        {{ set.result2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <a
      :href="agent.linecontact"
      class="line-contact"
    >
      <img
        src="/icon/line.gif"
        alt=""
        height="60"
      >
    </a>
  </validation-observer>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@validations'

// eslint-disable-next-line object-curly-newline
import { BCol, BFormCheckbox, BFormGroup, BFormInput, BImg, BRow } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment-timezone'

export default {
  name: 'Login',
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BImg,
    Swiper,
    SwiperSlide,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    // BForm,
    BFormGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      agent: {
        linecontact: 'https://line.me/R/ti/p/@505cevag',
      },
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      Ip: null,
      // ag: 1,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/banner/kingmakerAct.jpg') },
        { img: require('@/assets/banner/pgAct.webp') },
        { img: require('@/assets/banner/sexyAct.webp') },
        { img: require('@/assets/banner/spadeAct.webp') },
        { img: require('@/assets/banner/red.webp') },
        { img: require('@/assets/banner/kingmakerAct.jpg') },
        { img: require('@/assets/banner/pgAct.webp') },
        { img: require('@/assets/banner/sexyAct.webp') },
        { img: require('@/assets/banner/spadeAct.webp') },
        { img: require('@/assets/banner/red.webp') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      RoundData: {},
      R1: null, // รางวัลที่ 1 [THG]
      F3N1: null, // เลขหน้า 3 ตัว ที่1 [THG]
      F3N2: null, // เลขหน้า 3 ตัว ที่2 [THG]
      B3N1: null, // เลขท้าย 3 ตัว ที่1 [THG]
      B3N2: null, // เลขท้าย 3 ตัว ที่2 [THG]
      U3: null, // 3 ตัวบน [THG]
      U2: null, // 2 ตัวบน [THG]
      D2: null, // 2 ตัวล่าง [THG]
      EditID: null,
      ResultThai: {},
      ResultThaiDate: null,
      ResultAom: {},
      ResultAomDate: null,
      ResultTKS: {},
      ResultTKSDate: null,
      list: [],
      ResultYGLast: {},
      ResultYGLastDate: null,
      ResultYGRoundNo: null,
      ResultYGToday: {},
      ResultYGTodayDate: moment().format('DD/MM/YYYY'),
      ag: 0,
      logo_paths: '/logo/newjen.png',

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    this.getagid()
    // this.GetAgentData()
    this.GetDatas()
    this.GetResultThai(1, null)
    this.GetResultAom(3, null)
    this.GetResultTKS(2, null)
    this.GetResultYGLast()
    this.GetResultYG_Today()
    // console.log(this.$route.query.ref)
    // console.log(this.$route.query.password)
    if (this.$route.query.tel && this.$route.query.password) {
      this.fwlogin(this.$route.query.tel, this.$route.query.password)
    }
  },
  methods: {
    // GetAgentData() {
    //   this.$http
    //     .get('/agent/show')
    //     .then(response => {
    //       this.agent = response.data
    //       console.log(this.agent)
    //     })
    //     .catch(error => console.log(error))
    // },
    getagid() {
      this.ag = 7
      localStorage.setItem('wallet_endpoint', 'https://walletapi.ufobet.net/api')
      localStorage.setItem('logo_path', '/logo/newjen.png')
      this.logo_path = '/logo/newjen.png'
    },
    async GetDatas() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/LottoTypeList',
        )

        if (ResData) {
          this.show = false
          this.list = ResData
          this.GetResult()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultThai(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultThai = ResData.mes
          this.ResultThaiDate = moment(
            ResData.mes.RoundData.CloseDate,
          ).format('DD/MM/YYYY')
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultAom(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultAom = ResData.mes
          this.ResultAomDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultTKS(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultTKS = ResData.mes
          this.ResultTKSDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResult() {
      this.dateshow = moment(this.date).format('DD/MM/YYYY')
      if (this.datechange) {
        this.date = this.datechange
      }
      // eslint-disable-next-line no-restricted-syntax
      for await (const items of this.list) {
        const params = {
          LottoTypeHead: items.TypeID,
          date: this.date,
        }
        this.Result(params)
      }
    },
    async Result(params) {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/GetResultRewardByType',
          { params },
        )
        if (ResData.success) {
          let Index = null
          // console.log(ResData.mes)
          Index = this.list.findIndex(
            ({ TypeID }) => TypeID === ResData.mes.LottoTypeHead,
          )
          this.list[Index].result1 = ResData.mes.U3
          this.list[Index].result2 = ResData.mes.D2
        }
      } catch (e) {
        console.log(e)
        // return null
      }
    },
    async GetResultYGLast() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/ShowLastYG',
        )

        if (ResData.success) {
          this.ResultYGLast = ResData.mes
          this.ResultYGRoundNo = ResData.RoundNo
          this.ResultYGLastDate = moment(
            ResData.mes.RoundData.CloseDate,
          ).format('DD/MM/YYYY')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYG_Today() {
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/Reward/YGToDay',
        )

        if (ResData.success) {
          this.ResultYGToday = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },

    test() {
      console.log('asdadasd')
    },
    registerLink() {
      if (this.$route.query.ref) {
        this.$router.push({ name: 'register', query: { ref: this.$route.query.ref } })
      } else {
        this.$router.push({ name: 'register' })
      }
    },
    // async GetIp() {
    //   await axios
    //     .get('https://api.ipify.org')
    //     .then(response => {
    //       this.Ip = response.data
    //     })
    //     .catch(error => console.log(error))
    // },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.username,
              password: this.password,
              agent_id: this.ag,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              // console.log(response.data)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('CheckClick', true)
              // localStorage.setItem('token', JSON.stringify(userData).token)
              // localStorage.setItem('userData', JSON.parse(userData))
              this.$ability.update(userData.ability)
              this.$http
                .get('/auth/checklogin')
                .then(res => {
                  localStorage.setItem('CountLogin', res.data)
                })

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    SwalError(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: message,
          icon: 'LockIcon',
          variant: 'danger',
        },
      })
    },
    fwlogin(tel, pass) {
      useJwt
        .login({
          email: tel,
          password: pass,
          agent_id: this.ag,
          Detail: {
            Ip: this.Ip,
            UserAgent: navigator.userAgent,
            Platform: navigator.platform,
          },
        })
        .then(response => {
          const userData = response.data
          // console.log(response.data)
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // localStorage.setItem('token', JSON.stringify(userData).token)
          // localStorage.setItem('userData', JSON.parse(userData))
          this.$ability.update(userData.ability)
          this.$router
            .push(getHomeRouteForLoggedInUser(userData.role))
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
              icon: 'LockIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    comingSoon() {
      this.$swal({
        icon: 'info',
        title: '<h3 style="color: #FFF">Coming soon!</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },

}
</script>

<style>
.line-contact {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 999;

}
</style>
